(async function() {

  const gallery = document.querySelector('.gallery');
  const flkty = new Flickity( gallery, {
    cellAlign: 'left',
    prevNextButtons: false,
    wrapAround: true,
    groupCells: window.innerWidth < 600 ? 1 : 4,
  });

  const prev = document.querySelector('.prev .seta');
  const next = document.querySelector('.next .seta');

  prev.addEventListener('click', (e) => {
    e.preventDefault();
    flkty.previous();
  });

  next.addEventListener('click', (e) => {
    e.preventDefault();
    flkty.next();
  });
})();
